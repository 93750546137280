/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import Wrapper from "../components/grid/Wrapper";
import Grid from "../components/grid/Grid";
import Row from "../components/grid/Row";
import Cell from "../components/grid/Cell";
import Layout from "../components/layout";
import Button from "../components/form/Button";
import { Headline } from "../components/text";
import Seo from "../components/Seo";
import { Link } from "gatsby";

const NotFoundPage = () => (
    <Layout>
        <Seo title="Imprint" />
        <Wrapper>
            <Grid
                css={css`
                    margin-bottom: 10rem;
                `}
            >
                <Row
                    css={css`
                        margin-top: 22rem;
                        margin-bottom: 1rem;
                    `}
                >
                    <Cell
                        span={{
                            sm: 11 / 12,
                            lg: 12 / 12
                        }}
                    >
                        <Headline>Error 404</Headline>
                    </Cell>
                </Row>
                <Row>
                    <Cell
                        span={{
                            sm: 11 / 12,
                            lg: 3 / 12
                        }}
                    >
                        <Button as={Link} to="/">
                            Get back on the track
                        </Button>
                    </Cell>
                </Row>
            </Grid>
        </Wrapper>
    </Layout>
);

export default NotFoundPage;
